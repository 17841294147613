<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam.serialNum"></a-input>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="租赁时间">
                <a-range-picker @change="onChange" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!selectedRowKeys.length">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable } from '@/components';
import moment from 'moment';
import { getCustomer } from '@/api/common';
import debounce from 'lodash/debounce';
import { getWmsWarehouseRentPage, removeWmsWarehouseRent } from '@/api/wms'

export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {}, // 搜索条件
      customerOps: [], // 客户信息
      customerInfo: undefined,
      fetching: false,
      selectedRowKeys: [],
      loadData: parameter => {
        return getWmsWarehouseRentPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res;
        });
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num'
        },
        {
          title: '客户',
          dataIndex: 'customer_name'
        },
        {
          title: '租赁开始时间',
          dataIndex: 'lease_start_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '租赁结束时间',
          dataIndex: 'lease_end_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '费用',
          dataIndex: 'money'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      currentData: null
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'WmsWarehouseRent' && this.queryParam) {
        newRoute.meta.title = '仓储租赁业务';
        this.$nextTick(_ => {
          this.$refs.table.refresh(newRoute.query.isEdit);
        });
      }
    }
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: 'WmsWarehouseRentCUR',
        params: { id: 'create' }
      });
    },
    handleEdit(record) {
      this.currentData = record;
      this.$router.push({
        name: 'WmsWarehouseRentCUR',
        params: record,
        query: { disabled: true }
      });
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res;
        this.fetching = false;
      });
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.queryParam['customer_id'] = null;
      } else {
        this.queryParam['customer_id'] = value.key;
      }
      this.customerInfo = value;
      this.customerOps = [];
      this.fetching = false;
    },
    onChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['lease_start_date'] = startDate;
        this.queryParam['lease_end_date'] = endDate;
      } else {
        this.queryParam['lease_start_date'] = null;
        this.queryParam['lease_end_date'] = null;
      }
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          removeWmsWarehouseRent({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true);
          });
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
